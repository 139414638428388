import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as Plan } from 'assets/icons/price-tag.svg'

import { maskMoney } from 'util/masks'
import { ModalHeader } from 'util/modules/Modal'
import { ApplicationState } from 'AppReducer'
import { getAvailablePlans } from 'panel/plan/redux/actions'
import { createInvoice } from '../redux/actions'
import { useHistory } from 'react-router-dom'

const InvoiceForm: React.FC<{ onClose(): void }> = ({ onClose }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [selectedPlan, setSelectedPlan] = useState(0)

  const { selected, stores } = useSelector((state: ApplicationState) => state.storeReducer)
  const { available } = useSelector((state: ApplicationState) => state.planReducer)

  const store = stores.find(item => item.id === selected)

  function handleCreate () {
    if (selected && selectedPlan) {
      createInvoice(selected, selectedPlan)(dispatch).then(res => {
        if (res?.status === 'success' && res.data) {
          onClose()
          setSelectedPlan(0)

          history.push(`/panel/invoice/${res.data.id}/open`)
        }
      })
    }
  }

  useEffect(() => {
    if (selected) {
      getAvailablePlans(selected)(dispatch)
    }
  }, [selected, dispatch])

  useEffect(() => {
    if (store && store.plan?.id) {
      setSelectedPlan(store.plan.id)
    }
  }, [store])

  return (
    <div className="row panel no-padding">
      <ModalHeader title="Novo Plano" onClose={onClose} />
      <div className="row panel-body">

        {
          available.map((plan, i) => {
            const parts = /[R][$]\s(.+)([,][\d]{2})/g.exec(maskMoney(plan.price))
            if (!parts) return null

            return (
              <div
                key={i}
                className="row plan-tile"
                onClick={() => setSelectedPlan(plan.id)} data-active={plan.id === selectedPlan}
              >
                <Plan className="icon" />
                <div className="info">
                  <p className="title">{ plan.name }</p>
                  <p className="description">
                    <b className="badge-pill margin-lr-4 default">{ plan.effectiveDays } dias</b>
                    <b className="badge-pill margin-lr-4 secondary">{ plan.limitChannel } canais</b>
                    <b className="badge-pill margin-lr-4 secondary">{ plan.limitAttendants } atendentes</b>
                  </p>
                </div>
                <div className="price">
                  <div className="main">R$ <b>{ parts[1] }</b>{ parts[2] }</div>
                  { plan.priceOld > plan.price && <div className="old">{ maskMoney(plan.priceOld) }</div> }
                </div>

                { plan.bestOffer && <div className="best-offer">Melhor Oferta</div> }
              </div>
            )
          })
        }

        <div className="row justify-end buttons margin-top-16">
          <span className="button secondary" onClick={onClose}>Fechar</span>
          <button
            onClick={handleCreate}
            className="button gradient"
            disabled={selectedPlan === 0}
          >
              Contratar
          </button>
        </div>
      </div>
    </div>
  )
}

export default InvoiceForm
