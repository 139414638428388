import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import './styles/Invoice.scss'

import { getSocket } from 'util/services/SocketService'
import { ApplicationState } from 'AppReducer'
import { useDispatch, useSelector } from 'react-redux'
import { getInvoice, getInvoiceList } from './redux/actions'

import Modal from 'util/modules/Modal'
import InvoiceForm from './components/InvoiceForm'
import InvoiceList from './components/InvoiceList'
import InvoiceOpen from './components/InvoiceOpen'
import { hasPermission } from 'util/services/AuthService'

const Invoice: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [isFormOpen, setFormOpen] = useState(false)
  const [isInvoiceOpen, setInvoiceOpen] = useState(false)

  const { action } = useParams<{ [x: string]: string }>()
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  useEffect(() => {
    if (action === 'edit') {
      setFormOpen(true)
    } if (action === 'open') {
      setInvoiceOpen(true)
    } else {
      setFormOpen(false)
      setInvoiceOpen(false)
      history.push('/panel/invoice')
    }
  }, [action])

  useEffect(() => {
    if (!isInvoiceOpen && !isFormOpen) {
      history.push('/panel/invoice')
    }
  }, [isFormOpen, isInvoiceOpen])

  // Handle Socket
  useEffect(() => {
    const socket = getSocket()

    if (socket && selected) {
      socket.on('@Payment:Update', (invoiceId: number) => {
        getInvoice(selected, invoiceId)(dispatch)
        getInvoiceList(selected)(dispatch)
      })
    }

    return () => {
      if (socket && selected) {
        socket.off('@Payment:Update')
      }
    }
  }, [selected, dispatch])

  return (
    <div className="row panel bordered">
      <div className="row invoice-container">
        <div className="row padding-24">
          <p className="grow" style={{ fontSize: 18 }}>Faturas e Planos</p>

          {
            hasPermission('INVOICE:CREATE') &&
            <button className="gradient radius" style={{ width: 120 }} onClick={() => setFormOpen(true)}>
              Novo Plano
            </button>
          }
        </div>
        <InvoiceList />

        <Modal isOpen={isFormOpen} onClose={() => setFormOpen(false)}>
          <InvoiceForm onClose={() => setFormOpen(false)} />
        </Modal>
        <Modal isOpen={isInvoiceOpen} onClose={() => setInvoiceOpen(false)}>
          <InvoiceOpen onClose={() => setInvoiceOpen(false)} />
        </Modal>
      </div>
    </div>
  )
}

export default Invoice
