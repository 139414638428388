import { PaginationOpts } from 'util/types/index'
import { AlertActionTypes } from 'util/modules/Alert/redux/types'
import { Contact } from './contact'
import { Tag } from 'panel/tag/redux/types'

/**
* Data Types
*/
export interface Person {
  id: number,
  picture?: string,
  ignoreAutomation: boolean,
  ignoreConversation: boolean,
  firstName: string,
  lastName: string,
  alias?: string,
  name: string,
  email: string,
  docType?: 'CPF' | 'CNPJ' | 'IE' | 'CNH' | 'RG' | 'Passport',
  docNumber?: string,
  phone?: string,
  birthday?: string,
  note?: string,
  contacts: Partial<Contact>[],
  tag?: Tag,
  gender?: 'M' | 'F' | 'O',
  maritalStatus?: 'single' | 'married' | 'divorced' | 'widower' | 'commonLawMarriage',
  personType?: 'STANDARD' | 'BUSINESS',
  createdAt: string,
  updatedAt?: string
}

/**
* State Type
*/

export interface PersonState {
  readonly person?: Person
  readonly persons: { items: Person[], pagination?: PaginationOpts }
}

/**
* Action Enum Types
*/

export enum PersonTypes {
  PERSON_RESET = 'PERSON/RESET',
  PERSON_CREATE = 'PERSON/CREATE',
  PERSON_UPDATE = 'PERSON/UPDATE',
  PERSON_DELETE = 'PERSON/DELETE',
  PERSON_GET = 'PERSON/GET',
  PERSON_GET_LIST = 'PERSON/GET/LIST',
  PERSON_UPDATE_CONTACT = 'PERSON/UPDATE/CONTACT',
}

/**
* Action Types
*/
interface ResetAction {
  type: typeof PersonTypes.PERSON_RESET
}

interface CreateAction {
  type: typeof PersonTypes.PERSON_CREATE
  payload: Person
}

interface UpdateAction {
  type: typeof PersonTypes.PERSON_UPDATE,
  payload: Person
}

interface DeleteAction {
  type: typeof PersonTypes.PERSON_DELETE,
  payload: number
}

interface GetAction {
  type: typeof PersonTypes.PERSON_GET,
  payload: Person
}

interface GetListAction {
  type: typeof PersonTypes.PERSON_GET_LIST,
  payload: Person[]
  pagination: PaginationOpts
}

interface UpdateContactAction {
  type: typeof PersonTypes.PERSON_UPDATE_CONTACT,
  payload: Pick<Contact, 'id' | 'value' | 'status'>
}

export type PersonActionTypes = (
  AlertActionTypes | ResetAction | CreateAction | UpdateAction | DeleteAction | GetAction | GetListAction |
  UpdateContactAction
)
