import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Conversation } from 'panel/conversation/redux/types'
import { ApplicationState } from 'AppReducer'
import { redirectConversation } from './redux/actions'

import Modal from 'util/modules/Modal'

const initialState = { keepIn: false, attendant: 0, sector: 0, message: '' }
const RedirectChat: React.FC<{ conversation: Partial<Conversation> }> = ({ conversation }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [form, setForm] = useState(initialState)
  const [type, setType] = useState<'ATTENDANT'|'SECTOR'>('ATTENDANT')
  const [isModalOpen, setModalOpen] = useState(false)

  const { sectors } = useSelector((state: ApplicationState) => state.sectorReducer)
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { attendants } = useSelector((state: ApplicationState) => state.attendantReducer)

  async function handleSubmit (e: React.FormEvent): Promise<void> {
    e.preventDefault()

    if (selected && conversation && conversation.id) {
      redirectConversation(selected, conversation.id, form)(dispatch).then(res => {
        if (res?.status === 'success') {
          setForm(initialState)
          setModalOpen(false)
          setType('ATTENDANT')
          if (!form.keepIn) {
            history.push('/panel/conversation')
          }
        }
      })
    }
  }

  useEffect(() => {
    setForm(initialState)
  }, [type])

  return (
    <>
      <Modal isOpen={isModalOpen} closeModal={() => setModalOpen(false)}>
        <form className="panel tile-modal-card" style={{ width: 576 }} onSubmit={handleSubmit} >
          <h3><i className="fa fa-plus" style={{ marginLeft: 0 }} /> Novo Atendimento</h3>

          <div className="row margin-top-16">
            <label className="form-control justify-start flex" style={{ marginLeft: 0 }}>
              <input
                readOnly
                type="radio"
                checked={type === 'ATTENDANT'}
                onClick={(): void => setType('ATTENDANT')}
              />
              &nbsp;Atendente
            </label>
            <label className="form-control justify-start flex">
              <input
                readOnly
                type="radio"
                checked={type === 'SECTOR'}
                onClick={(): void => setType('SECTOR')}
              />
              &nbsp;Setor
            </label>
          </div>

          <div className="form-control justify-start">
            <label>Selecione o Setor</label>
            <select
              required
              value={form.sector}
              onChange={(e) => setForm({ ...form, sector: Number(e.target.value) })}
            >
              <option></option>
              { sectors.map((sector, i) => <option key={i} value={sector.id}>{ sector.name }</option>) }
            </select>
          </div>
          {
            type === 'ATTENDANT' && (
              <div className="form-control justify-start">
                <label>Selecione o Atendente</label>
                <select
                  required
                  value={form.attendant}
                  onChange={(e) => setForm({ ...form, attendant: Number(e.target.value) })}
                >
                  <option></option>
                  {
                    attendants
                      .filter(item => item.sectorAttendants?.find(s => s.sector?.id === form.sector))
                      .map((attendant, i) => (
                        <option key={i} value={attendant.id} > {attendant.user?.person?.name}</option>
                      ))
                  }
                </select>
              </div>
            )
          }

          <div className="form-control">
            <label>Mensagem</label>
            <input
              type="text"
              value={form.message}
              placeholder="Opcional"
              onChange={(e): void => setForm({ ...form, message: e.target.value })}
            />
          </div>
          <div className="row">
            <label className="form-control justify-start">
              <input
                type="checkbox"
                checked={form.keepIn}
                onClick={() => setForm({ ...form, keepIn: !form.keepIn })}
                readOnly
              />
              &nbsp;Permanecer na conversa
            </label>
          </div>

          <div className="form-control">
            <button className="primary col-12">Redirecionar atendimento</button>
          </div>
        </form>

      </Modal>
      <span className="button white" onClick={() => setModalOpen(true)} title="Redirecionar Atendimento">
        <i className="fas fa-paper-plane" />
      </span>
    </>
  )
}

export default RedirectChat
