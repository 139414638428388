import axios from 'axios'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import './styles/react-flow.scss'
import 'reactflow/dist/style.css'
import { MarkerType } from 'reactflow'
import { Edge, EdgeChange, Node, NodeChange, applyEdgeChanges, applyNodeChanges } from 'reactflow'
import ReactFlow, { Background, SmoothStepEdge, Connection, Controls, ReactFlowInstance, addEdge } from 'reactflow'

import { nodeTypes } from './components/nodes'
import { hasPermission } from 'util/services/AuthService'
import { ApplicationState } from 'AppReducer'
import { getSellerClientList } from 'panel/store/redux/actions'
import { ChatBotNode, ChatBotSidebar } from './components/sidebar'
import { getChatBot, getId, saveChatBot } from './service/chat-bot.service'
import { AttachmentManagement } from './components/attachment/attachment.management'
import { getChatBotAttachmentList } from './service/chat-bot.attachment.service'
import { NodeOptionItem } from './components/nodes/helpers/on-change-value.hook'

const startNode = {
  id: getId(),
  type: 'start',
  data: { type: ChatBotNode.START },
  position: { x: 0, y: 0 }
}

const initialState: { edges: Edge[], nodes: Node[] } = {
  nodes: [startNode],
  edges: []
}

const ChatBotNew: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [isFitView, setIsFitView] = useState(false)
  const [items, setItems] = useState<{ edges: Edge[], nodes: Node[] }>(initialState)
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null)

  const { data: attachments } = getChatBotAttachmentList()
  const { sectors } = useSelector((state: ApplicationState) => state.sectorReducer)
  const { attendants } = useSelector((state: ApplicationState) => state.attendantReducer)

  const { data: chatBot, isFetching } = getChatBot()
  const { mutate: onSaveChatBot, isLoading } = saveChatBot()
  const { selected, stores } = useSelector((state: ApplicationState) => state.storeReducer)

  const download = () => {
    const filename = 'bot.json'
    const content = JSON.stringify(items)
    const blob = new Blob([content], { type: 'application/json' })

    try {
      (window.navigator as any).msSaveBlob(blob, filename)
    } catch {
      const elem = window.document.createElement('a')
      elem.href = window.URL.createObjectURL(blob)
      elem.download = filename
      document.body.appendChild(elem)
      elem.click()
      document.body.removeChild(elem)
    }
  }

  const handleImport = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) return

    if (!uploadRef.current) return
    uploadRef.current.value = ''

    const reader = new FileReader()
    reader.onload = (event) => {
      if (!event.target) return

      let content = event.target.result as string

      try {
        let data: { edges: Edge[], nodes: Node[] } = JSON.parse(content)
        if (!data) throw new Error('')
        if (!data.nodes) throw new Error('Nodes')
        if (!data.edges) throw new Error('Edges')

        data.nodes.forEach(node => {
          content = content.replaceAll(node.id, getId())

          if (node.data && node.data.options) {
            node.data.options.forEach((option: any) => {
              content = content.replaceAll(option.id, getId())
            })
          }
        })
        data.edges.forEach(edge => {
          content = content.replaceAll(edge.id, getId())
        })
        data = JSON.parse(content)

        data.nodes.forEach(node => {
          if (node.type === 'redirectSector') {
            const sectorExists = sectors.some(sector => sector.id === node.data.value)
            if (!sectorExists) node.data.value = ''
          }
          if (node.type === 'redirectAttendantNode') {
            const attendantExists = attendants.some(attendant => attendant.id === node.data.value)
            if (!attendantExists) node.data.value = ''
          }
          if (node.type === 'sendAttachment') {
            const type = (node.data.options as NodeOptionItem[])?.find(item => item.key === 'type')
            const attachment = (node.data.options as NodeOptionItem[])?.find((item) => item.key === 'attachmentId')
            if (type && attachment) {
              const attachmentExists = attachments.some(item => item.id === attachment.value)
              if (!attachmentExists) {
                node.data.options = [
                  { ...type },
                  { ...attachment, value: '' }
                ]
              }
            }
          }
        })

        setItems(data)
      } catch (e) {
        console.log(e)
        alert('Arquivo inválido')
      }
    }

    reader.readAsText(file)
  }

  const handleSave = () => {
    onSaveChatBot({ nodes: items.nodes, connections: items.edges })
  }

  const onConnect = useCallback((params: Connection) => {
    const isTheSameNode = params.source === params.target
    if (isTheSameNode) return

    setItems((items) => {
      const { edges, nodes } = items

      const alreadyConnected = edges.some(item => item.source === params.source)
      if (alreadyConnected) {
        const node = nodes.find(node => node.id === params.source)
        if (!node) return items

        const isMenu = node.data.type === ChatBotNode.MENU
        if (!isMenu) return items
      }

      const updatedEdges = addEdge({
        ...params,
        id: getId(),
        style: {
          strokeWidth: 2,
          stroke: '#332FA4'
        },
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 12,
          height: 12,
          color: '#332FA4'
        }
      }, items.edges)

      return { edges: updatedEdges, nodes }
    })
  }, [])

  const onNodesChange = useCallback((changes: NodeChange[]) => {
    if ((changes[0] as any).type === 'start') return
    setItems((items) => {
      const newItems = applyNodeChanges(changes, items.nodes)
      return {
        nodes: newItems,
        edges: items.edges.filter(edge => {
          const sourceExists = newItems.some(node => node.id === edge.source)
          const targetExists = newItems.some(node => node.id === edge.target)

          return sourceExists && targetExists
        })
      }
    })
  }, [])

  const onEdgesChange = useCallback((changes: EdgeChange[]) => {
    setItems((items) => {
      const newItems = applyEdgeChanges(changes, items.edges)
      return { ...items, edges: newItems }
    })
  }, [])

  const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }, [])

  const onDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (!reactFlowInstance) return null

    const type = event.dataTransfer.getData('application/reactflow')

    // check if the dropped element is valid
    if (typeof type === 'undefined' || !type) {
      return
    }

    // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
    // and you don't need to subtract the reactFlowBounds.left/top anymore
    // details: https://reactflow.dev/whats-new/2023-11-10
    const position = reactFlowInstance.screenToFlowPosition({
      x: event.clientX,
      y: event.clientY
    })

    const options: { id: string, key: string, value: string }[] = []

    if (type === ChatBotNode.FILL_NAME) {
      options.push({ id: getId(), key: 'Nome', value: 'Qual é o seu primeiro nome?' })
      options.push({ id: getId(), key: 'Sobrenome', value: 'Qual é o seu sobrenome?' })
    }

    if (type === ChatBotNode.FILL_EMAIL) {
      options.push({ id: getId(), key: 'E-mail', value: 'Qual é o seu endereço de e-mail?' })
    }

    if (type === ChatBotNode.FILL_CPF) {
      options.push({ id: getId(), key: 'CPF', value: 'Qual é o número do seu CPF?' })
    }

    if (type === ChatBotNode.FILL_CNPJ) {
      options.push({ id: getId(), key: 'CNPJ', value: 'Qual é o número do seu CNPJ?' })
    }

    if (type === ChatBotNode.SEND_TEXT) {
      options.push({ id: getId(), key: 'trigger', value: 'AUTOMATIC' })
      options.push({ id: getId(), key: 'activation', value: 'ALL' })
    }

    if (type === ChatBotNode.SEND_ATTACHMENT) {
      options.push({ id: getId(), key: 'type', value: 'IMAGE' })
      options.push({ id: getId(), key: 'attachmentId', value: '' })
    }

    const newNode = {
      id: getId(),
      type: {
        [ChatBotNode.MENU]: 'menu',
        [ChatBotNode.FILL_CPF]: 'fillData',
        [ChatBotNode.FILL_CNPJ]: 'fillData',
        [ChatBotNode.FILL_NAME]: 'fillData',
        [ChatBotNode.FILL_EMAIL]: 'fillData',
        [ChatBotNode.SEND_TEXT]: 'sendText',
        [ChatBotNode.SEND_ATTACHMENT]: 'sendAttachment',
        [ChatBotNode.REDIRECT_SECTOR]: 'redirectSector',
        [ChatBotNode.REDIRECT_ATTENDANT]: 'redirectAttendantNode',
        [ChatBotNode.FINISH_CONVERSATION]: 'finishConversation'
      }[type],
      position,
      data: { type, value: '', label: '', options },

      // Specify the custom class acting as a drag handle
      dragHandle: '.custom-drag-handle'
    }

    setItems((items) => {
      return {
        ...items,
        nodes: items.nodes.concat(newNode)
      }
    })
  }, [reactFlowInstance])

  function handleToggleBotVersion () {
    axios.post(`/store/${selected}/toggle-bot-version`).then((res) => {
      if (res.data.status === 'success') {
        getSellerClientList()(dispatch)
      }
    })
  }

  const store = stores.find((store) => store.id === selected)

  useEffect(() => {
    if (!store) return
    if (!store.useNewBotVersion) history.push('/panel/chatbot')
  }, [store])

  useEffect(() => {
    if (!chatBot) return
    if (isFetching) return
    if (!chatBot.nodes) return
    if (!reactFlowInstance) return
    if (!chatBot.connections) return

    setItems({
      nodes: chatBot.nodes.length ? chatBot.nodes.map(node => JSON.parse(node.meta)) : [startNode],
      edges: chatBot.connections.map(connection => JSON.parse(connection.meta))
    })
  }, [chatBot, reactFlowInstance, isFetching])

  useEffect(() => {
    if (isFitView) return
    if (!reactFlowInstance) return
    if (items.nodes.length <= 1) return

    reactFlowInstance.fitView()
    setIsFitView(true)
  }, [reactFlowInstance, items.nodes.length])

  const uploadRef = useRef<HTMLInputElement>(null)

  if (!store) return null

  return (
    <div className="chatbot row">
      <div className="row chat-bot-options">
        <div className="row grow align-center justify-start" style={{ flexDirection: 'row' }}>
          <p style={{ fontSize: 18, width: 'auto' }}>Configuração de Chat-Bot</p>
          {
            store && new Date(store.createdAt) < new Date('2024-07-18') &&
            <div
              style={{ width: 150 }}
              onClick={handleToggleBotVersion}
              className="button radius secondary margin-left-16"
            >
              Usar versão antiga
            </div>
          }
          <a
            rel="noreferrer"
            href="https://www.youtube.com/watch?v=P8ehTY92Y9E"
            style={{ width: 120, backgroundColor: '#c4302b', color: 'white' }}
            target='_blank'
            className="button radius margin-left-16"
          >
            <i className="fab fa-youtube"/> Tutorial
          </a>
        </div>
        <div className="row flex justify-end" style={{ gap: 8 }}>
          {
            hasPermission('CHAT_BOT:CREATE') &&
            <>
              <button className="button secondary radius" type="button" onClick={() => uploadRef.current?.click()}>
                <i className="fa fa-upload"/> Importar
              </button>
              <input ref={uploadRef} onChange={handleImport} type="file" style={{ display: 'none' }} />
              <button className="button secondary radius" type="button" onClick={download}>
                <i className="fa fa-download"/> Backup
              </button>
              <button disabled={isLoading} className="gradient radius button" onClick={handleSave}>
                Salvar
              </button>
            </>
          }
        </div>
        <div className='flow-box'>
          <ChatBotSidebar />
          <ReactFlow
            snapToGrid
            nodesConnectable
            elementsSelectable
            nodes={items.nodes}
            edges={items.edges}
            nodeTypes={nodeTypes}
            onConnect={onConnect}
            edgeTypes={{ default: SmoothStepEdge }}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onInit={setReactFlowInstance}
            deleteKeyCode={['8', '46', 'Backspace', 'Delete']} // Backspace and Delete
            defaultViewport={{ x: 8, y: 8, zoom: 1 }}
          >
            <Background />
            <Controls position='bottom-right' />
          </ReactFlow>
        </div>
      </div>
      <AttachmentManagement />
    </div>
  )
}

export default ChatBotNew
