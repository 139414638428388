import React, { useEffect, useRef, useState } from 'react'

import { maskCNPJ, maskCPF, maskDate, maskPhone } from 'util/masks'
import { Conversation } from 'panel/conversation/redux/types'
import { defaultPicture } from 'index'

import { useDispatch, useSelector } from 'react-redux'

import Modal from 'util/modules/Modal'
import Upload, { UploadRef } from 'util/modules/Upload'
import { deleteContactPicture } from 'panel/person/redux/action/contact'
import { ApplicationState } from 'AppReducer'
import { updatePerson } from 'panel/person/redux/action/person'
import { getConversation, getConversationList } from 'panel/conversation/redux/actions'
import TagSelector from 'panel/tag/components/TagSelector'

const initialState = {
  firstName: '',
  lastName: '',
  alias: '',
  email: '',
  phone: '',
  docNumber: '',
  gender: 'O',
  maritalStatus: '',
  birthday: '',
  note: '',
  tag: 0,
  ignoreAutomation: false
}
const ContactInfo: React.FC<{ conversation: Conversation }> = ({ conversation }) => {
  const uploadRef = useRef<UploadRef>(null)

  const contact = conversation.client
  const person = contact?.person
  const hasPersonInfo = !!person?.name
  const id = person?.id

  const dispatch = useDispatch()
  const [isModalOpen, setModalOpen] = useState(false)

  const [form, setForm] = useState(initialState)
  const [picture, setPicture] = useState<File>()
  const [fetching, setFetching] = useState(false)

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  function handleRemove () {
    if (selected && id && window.confirm('Tem certeza que deseja remover a imagem?')) {
      deleteContactPicture(selected, id)(dispatch)
    }
  }

  async function handleSubmit (e: React.FormEvent): Promise<void> {
    e.preventDefault()

    if (!selected) return undefined

    const formData = new FormData()

    if (picture) formData.set('picture', picture)
    if (form.birthday) formData.set('birthday', form.birthday || '')

    formData.set('firstName', form.firstName || '')
    formData.set('lastName', form.lastName || '')
    formData.set('alias', form.alias || '')
    formData.set('email', form.email || '')
    formData.set('phone', form.phone || '')
    formData.set('docNumber', form.docNumber || '')
    formData.set('gender', form.gender || 'O')
    formData.set('maritalStatus', form.maritalStatus || '')
    formData.set('note', form.note || '')
    formData.set('tag', String(form.tag))
    formData.set('ignoreAutomation', (form.ignoreAutomation ? 1 : 0) as unknown as string)

    setFetching(true)
    if (id) {
      await updatePerson(selected, id, formData)(dispatch)
      await getConversation(selected, conversation.id)(dispatch)
      await getConversationList(selected)(dispatch)
    }
    setFetching(false)
  }

  useEffect(() => {
    if (person && contact && uploadRef.current) {
      if (person.picture) uploadRef.current.setPreviewImage(person.picture)

      setForm({
        firstName: person.firstName || '',
        lastName: person.lastName || '',
        alias: person.alias || '',
        email: person.email || '',
        docNumber: person.docNumber || '',
        phone: maskPhone(contact.value),
        gender: person.gender || 'O',
        maritalStatus: person.maritalStatus || '',
        birthday: person.birthday ? person.birthday.split('-').reverse().join('/') : '',
        note: person.note || '',
        tag: person.tag?.id || 0,
        ignoreAutomation: !!person.ignoreAutomation
      })
    }
  }, [person, contact, uploadRef])

  const onClose = () => setModalOpen(false)

  const alias = conversation.client?.person?.alias
  return (
    <>
      <Modal isOpen={isModalOpen} closeModal={onClose} className="contact-info-right-modal">
        <form onSubmit={handleSubmit} className="row panel no-padding">
          <div className='row panel-header'>
            <p className="flex padding-left-8"><i className="fa fa-user"/> Informações do contato</p>
            <span className="button-times pointer" onClick={onClose}><i className="fa fa-times"/></span>
          </div>
          <div className="row panel-body">
            <div className="row">
              <div className="row" style={{ width: 160, height: 160, position: 'relative' }} >
                <i className="fa fa-trash upload-remove-btn pointer" onClick={handleRemove} />
                <Upload ref={uploadRef} placeholder={false} onChange={setPicture} />
              </div>
              <div className="grow padding-left-16" style={{ marginTop: -8 }}>
                <div className="row">
                  <div className="row form-control col-6 margin-top32">
                    <label>Nome</label>
                    <input
                      type="text"
                      value={form.firstName}
                      onChange={(e) => setForm({ ...form, firstName: e.target.value })}
                    />
                  </div>
                  <div className="row form-control col-6 margin-top32">
                    <label>Sobrenome</label>
                    <input
                      type="text"
                      value={form.lastName}
                      onChange={(e) => setForm({ ...form, lastName: e.target.value })}
                    />
                  </div>
                  <div className="row form-control col-6 margin-top32">
                    <label>Apelido</label>
                    <input
                      type="text"
                      value={form.alias}
                      onChange={(e) => setForm({ ...form, alias: e.target.value })}
                    />
                  </div>
                  <div className="form-control col-6 margin-top32">
                    <label>E-mail</label>
                    <input
                      type="text"
                      value={form.email}
                      onChange={(e) => setForm({ ...form, email: e.target.value })}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="form-control col-6">
              <label>Etiqueta</label>
              <TagSelector
                value={form.tag}
                onSelect={(tag) => setForm({ ...form, tag })}
                onRemove={() => setForm({ ...form, tag: 0 })}
              />
            </div>
            <div className="form-control col-6 margin-top32 justify-start">
              <label>Ignorar automação?</label>
              <select
                value={form.ignoreAutomation ? 1 : 0}
                onChange={(e) => setForm({ ...form, ignoreAutomation: parseInt(e.target.value) === 1 })}
              >
                <option value={0}>Não</option>
                <option value={1}>Sim</option>
              </select>
            </div>
            <div className="form-control col-6 margin-top32">
              <label>Telefone</label>
              <input
                type="text"
                value={form.phone}
                onChange={(e) => setForm({ ...form, phone: maskPhone(e.target.value) })}
              />
            </div>
            <div className="form-control col-6 margin-top32">
              <label>Documento</label>
              <input
                type="text"
                value={form.docNumber}
                onChange={(e) => {
                  const isCNPJ = person?.docNumber?.replace(/\D/g, '').length === 14
                  if (isCNPJ) setForm({ ...form, docNumber: maskCNPJ(e.target.value) })
                  if (!isCNPJ) setForm({ ...form, docNumber: maskCPF(e.target.value) })
                }}
              />
            </div>
            <div className="form-control col-4 margin-top32">
              <label>Data de nascimento</label>
              <input
                type="text"
                value={form.birthday}
                onChange={(e): void => setForm({ ...form, birthday: maskDate(e.target.value, '/') })}
              />
            </div>
            <div className="form-control col-4 margin-top32">
              <label>Sexo</label>
              <select
                value={form.gender}
                onChange={(e) => setForm({ ...form, gender: e.target.value })}
              >
                <option value='O'>Não Definido</option>
                <option value='M'>Masculino</option>
                <option value='F'>Feminino</option>
              </select>
            </div>
            <div className="form-control col-4 margin-top32">
              <label>Estado civil</label>
              <select
                value={form.maritalStatus}
                onChange={(e) => setForm({ ...form, maritalStatus: e.target.value })}
              >
                <option></option>
                <option value="single">Solteiro(a)</option>
                <option value="married">Casado(a)</option>
                <option value="divorced">Divorciado(a)</option>
                <option value="widower">Viúvo(a)</option>
                <option value="commonLawMarriage">União Estável</option>
              </select>
            </div>

            <div className="row form-item margin-top32" style={{ alignItems: 'flex-start' }}>
              <label>Anotações</label>
              <textarea
                rows={4}
                value={form.note}
                onChange={(e) => setForm({ ...form, note: e.target.value })}
              />
            </div>

            <div className="row justify-end margin-top-16">
              <button disabled={fetching} className="gradient col-12">Salvar Informações</button>
            </div>
          </div>
        </form>
      </Modal>
      <div
        style={{ cursor: 'pointer', flexWrap: 'nowrap' }}
        className="row justify-start user-info-wrapper"
        onClick={() => hasPersonInfo ? setModalOpen(true) : null}
      >
        <img
          alt="User"
          width={40}
          height={40}
          className='img-circle'
          src={conversation.client?.person?.picture || defaultPicture}
        />

        <div className="padding-8">
          <strong style={{ fontSize: 12, color: '#76838F' }}>
            { conversation.client?.person?.name || 'Removido' }
            { alias ? ` (${alias})` : '' }
            <i
              style={{ marginLeft: 4 }}
              className="fa fa-info-circle"
              title='Clique para abrir e/ou editar'
            />
          </strong>
          <p style={{ color: '#76838F', fontSize: 9 }}>
            { maskPhone(conversation.client?.value || conversation.clientValue) }
          </p>
        </div>
      </div>
    </>
  )
}

export default ContactInfo
